<template>
    <!--begin::Dashboard-->
    <div class="p-5 container">
      <div class="row mb-5" style="padding: 2vh;">
        <div class="col-12 col-lg-8">
          <p class="section-header" style="font-weight: 700;color: #333333;">
            {{ listData ? listData.title : "" }}
          </p>
          <div v-if="showYoutube">
            <iframe width="100%" height="400px" :src="currentYoutube"> </iframe>
          </div>
          <div v-if="!showYoutube" :key="videoReload">
            <video
              ref="videoRef"
              v-if="showVideo"
              controls
              style="width: 100%;"
              @ended="updateProgress()"
              @pause="logDuration"
            >
              <!-- @play="onPlay()" -->
              <!-- http://energylearning.dede.go.th/dede-cms-api/api/v1/streaming/p/Y291cnNlL2NoYXB0ZXIvNDgvY29udGVudC84MS8yMDIwMTAwM18wMTIyNDRfMTYwMTY2Mjk2NDIzOS5tcDQ=/dl/f -->
              <source :src="currentVideo" />
              <!-- <source
              :src="
                listChapter && listChapter.contentFiles
                  ? imageUrl + listChapter.contentFiles[0].fUrl
                  : ''
              "
              type="video/webm"
            /> -->
            </video>
            <img
              v-else
              :src="listData ? imageUrl + listData.thumbnailsPath : ''"
              alt=""
              class="w-100 mb-3"
              style="border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              object-fit: cover;
              height: 400px;border-radius: 20px;
              border: 1px solid #dbdbdb;"
            />
          </div>
  
          <div class="row">
            <div class="col-12">
              <div
                class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
              >
                <span>ความก้าวหน้า</span>
                <span>{{ listData ? listData.progressUpdate : "" }}%</span>
              </div>
              <div class="h-8px mx-3 w-100 rounded" style="background:#E1F2FF">
                <div
                  class="bg-progressbar rounded h-8px"
                  data-bs-toggle="tooltip"
                  :title="(listData ? listData.progressUpdate : '') + '%'"
                  role="progressbar"
                  :style="
                    'width: ' + (listData ? listData.progressUpdate : '') + '%'
                  "
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="mt-3">
                <button type="button" @click="detail()" class="btn btn-lern mx-2">
                  รายละเอียดหลักสูตร
                </button>
                <button
                  type="button"
                  @click="document()"
                  class="btn btn-lern mx-2"
                >
                  เอกสารประกอบหลักสูตร
                </button>
                <button
                  type="button"
                  @click="bookmark()"
                  class="btn btn-lern mx-2"
                >
                  <i
                    :class="
                      showBookmark == true
                        ? 'fa fa-bookmark text-success'
                        : 'fa fa-bookmark'
                    "
                    style="padding: 0"
                  ></i>
                </button>
              </div>
            </div>
            <div class="col-12 col-md-4" style="text-align: right;">
              <div style="display: flex;justify-content: flex-end;" class="mt-3">
            <p class="my-auto">แชร์ไปยัง :</p>
            <img
              @click="share('facebook')"
              src="media/icon-app/facebook.svg"
              class="mx-1"
              alt=""
            />
            <img
              @click="share('line')"
              src="media/icon-app/line.svg"
              class="mx-1"
              alt=""
            />
            <img
              @click="share('twitter')"
              src="media/icon-app/twitter.svg"
              class="mx-1 w-30px"
              alt=""
            />
          </div>
            </div>
          </div>
          <div class="my-5" v-if="showDetail">
            <p style="font-weight: 700;color: #333333;">
              วัตถุประสงค์ (Objective)
            </p>
            <p
              v-html="listData ? listData.objective : ''"
              style="font-weight: 400;
                  color: #7F7F81;"
            ></p>
            <p style="font-weight: 700;color: #333333;">
              รายละเอียด (Description)
            </p>
            <p
              v-html="listData ? listData.description : ''"
              style="font-weight: 400;
                  color: #7F7F81;"
            ></p>
          </div>
          <div class="my-5" v-if="showDocument">
            <div v-if="listData.pdfPath">
              <div
                class="my-2"
                style="background: #FFFFFF;
                    border-radius: 10px;
                    padding: 20px;"
                v-for="(item, index) in listData.pdfPath"
                :key="index"
              >
                <i class="fa fa-file text-danger"></i>
                <a :href="imageUrl + item.fileName" target="_blank"
                  >&nbsp; {{ item.name }}</a
                >
              </div>
            </div>
            <div v-if="listData.xlsPath">
              <div
                class="my-2"
                style="background: #FFFFFF;
                    border-radius: 10px;
                    padding: 20px;"
                v-for="(item, index) in listData.xlsPath"
                :key="index"
              >
                <i class="fa fa-file text-success"></i>
                <a :href="imageUrl + item.fileName" target="_blank"
                  >&nbsp; {{ item.name }}</a
                >
              </div>
            </div>
            <div v-if="listData.docPath">
              <div
                class="my-2"
                style="background: #FFFFFF;
                    border-radius: 10px;
                    padding: 20px;"
                v-for="(item, index) in listData.docPath"
                :key="index"
              >
                <i class="fa fa-file text-primary"></i>
                <a :href="imageUrl + item.fileName" target="_blank"
                  >&nbsp; {{ item.name }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <p
            class="section-header"
            style="font-style: normal;
                    font-weight: 700;
                    color: #333333;
                    "
          >
            บทเรียน
          </p>
          <div v-if="listSection">
            <div v-for="(item, index) in listSection" :key="index">
              <div class="accordion" :id="'kt_accordion_' + index">
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    :id="'kt_accordion_header_' + index"
                  >
                    <button
                      class="accordion-button fs-4 fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#kt_accordion_body_' + index"
                      aria-expanded="true"
                      :aria-controls="'kt_accordion_body_' + index"
                    >
                      ตอนที่ {{ index + 1 }}
                    </button>
                  </h2>
                  <div
                    :id="'kt_accordion_body_' + index"
                    class="accordion-collapse collapse show"
                    :aria-labelledby="'kt_accordion_header_' + index"
                    :data-bs-parent="'#kt_accordion_' + index"
                  >
                    <div class="accordion-body">
                      <div v-for="(itemC, indexC) in item.chapters" :key="indexC">
                        <div v-if="itemC.examId">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            :disabled="itemC.flgSuccess === true"
                            @click="
                              getExam(
                                item.id,
                                itemC.chapterId,
                                itemC.examId,
                                'pretest'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          >
                            <i class="las la-file-alt"></i>
                            {{ itemC.titleChapter }}
                            <span
                              v-html="
                                itemC && itemC.flgSuccess == true
                                  ? `<i class='fa fa-check-circle text-success'></i>`
                                  : ''
                              "
                            ></span>
                          </button>
                        </div>
                        <div v-else-if="itemC.videoPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            @click="videoExam(item.id, itemC.chapterId, true)"
                            class="btn btn-default w-100 my-2"
                          >
                            <i class="las la-file-video"></i>
                            {{ itemC.titleChapter }}
                            <span
                              v-html="
                                itemC && itemC.flgSuccess == true
                                  ? `<i class='fa fa-check-circle text-success'></i>`
                                  : ''
                              "
                            ></span>
                          </button>
                        </div>
                        <div v-else-if="itemC.docPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.docPath,
                                'doc'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          >
                            <i class="las la-file-pdf"></i>
                            {{ itemC.titleChapter }}
                            <span
                              v-html="
                                itemC && itemC.flgSuccess == true
                                  ? `<i class='fa fa-check-circle text-success'></i>`
                                  : ''
                              "
                            ></span>
                          </button>
                        </div>
                        <div v-else-if="itemC.pdfPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.pdfPath,
                                'pdf'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          >
                            <i class="las la-file-pdf"></i>
                            {{ itemC.titleChapter }}
                            <span
                              v-html="
                                itemC && itemC.flgSuccess == true
                                  ? `<i class='fa fa-check-circle text-success'></i>`
                                  : ''
                              "
                            ></span>
                          </button>
                        </div>
                        <div v-else-if="itemC.xlsPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.xlsPath,
                                'xls'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          >
                            <i class="las la-file-pdf"></i>
                            {{ itemC.titleChapter }}
                            <span
                              v-html="
                                itemC && itemC.flgSuccess == true
                                  ? `<i class='fa fa-check-circle text-success'></i>`
                                  : ''
                              "
                            ></span>
                          </button>
                        </div>
                        <div v-else-if="itemC.youtube">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.youtube,
                                'youtube'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          >
                            <i class="las la-file-pdf"></i>
                            {{ itemC.titleChapter }}
                            <span
                              v-html="
                                itemC && itemC.flgSuccess == true
                                  ? `<i class='fa fa-check-circle text-success'></i>`
                                  : ''
                              "
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import axios from "axios";
  import { useRoute, useRouter } from "vue-router";
  const videoReload = ref(0);
  const currentVideo = ref();
  const currentYoutube = ref();
  const listData = ref();
  const listSection = ref();
  const videoRef = ref();
  const currentChId = ref();
  const showDetail = ref(true);
  const showDocument = ref(false);
  const showBookmark = ref(false);
  const listDocument = ref();
  const showYoutube = ref(false);
  const showVideo = ref(false);
  const currentUpdate = ref({
    sectionId: "",
    chapterId: "",
  });
  const currentDuration = ref(0);
  import Swal from "sweetalert2/dist/sweetalert2.min.js";

  export default defineComponent({
    name: "exam-learn",
    components: {},
    data() {
      return {
        imageUrl: process.env.VUE_APP_IMG_URL,
      };
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      onMounted(() => {
        /* eslint-disable */
        getDataList();
        // getDocuments();
        // getBookmark();
      });
      const getDataList = () => {
        showVideo.value = false;
        axios
          .get(
            process.env.VUE_APP_API_URL + "/getCourse?id=" + route.params.id,
            // "&categories=" +
            // categories.value
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.data.content[0]);
            listData.value = response.data.data.content[0];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getChapterList();
            setCurrentPageBreadcrumbs(listData.value.title, []);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const getChapterList = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getCourseSection?courseId=" +
              route.params.id,
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.data.content);
            listSection.value = response.data.data.content;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            // videoExam(listData.value.chapter.chapterId, false);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const getDocuments = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/api/v1/courses/" +
              route.params.id +
              "/documents",
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            listDocument.value = response.data.result[0].downloadFiles;
            // console.log(response.data.result[0].downloadFiles);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const getBookmark = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/api/v1/courses/" +
              route.params.id +
              "/bookmark",
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.result);
            if (response.data.result.length > 0) {
              showBookmark.value = true;
            } else {
              showBookmark.value = false;
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const videoExam = async (secId, chId, autoPlay) => {
        currentUpdate.value.sectionId = secId;
        currentUpdate.value.chapterId = chId;
        showVideo.value = true;
        showYoutube.value = false;
        currentChId.value = chId;
        let flgSuccess = false;
        for (const loopSection of listSection.value) {
          if (secId == loopSection.id) {
            for (const loopChapter of loopSection.chapters) {
              if (loopChapter.chapterId == chId) {
                // console.log(loopChapter);
                currentVideo.value =
                  process.env.VUE_APP_IMG_URL + loopChapter.videoPath;
                flgSuccess = loopChapter.flgSuccess;
                videoReload.value += 1;
              }
            }
          }
        }
        videoRef.value.load();
        if (flgSuccess != true) {
          let currentTime = await axios.get(
            process.env.VUE_APP_API_URL +
              `/getCourseVideoProgress?courseId=${route.params.id}&sectionId=${secId}&chapterId=${chId}`,
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          );
          if (currentTime.data.data.length > 0) {
            videoRef.value.currentTime = currentTime.data.data[0].elapsed;
          }
        }
        if (autoPlay == true) {
          videoRef.value.play();
        }
      };
      const getExam = (secId, chId, exId, type) => {
        // console.log(chId);
        Swal.fire({
          title: "แบบทดสอบก่อนเรียน",
          text:
            "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          allowOutsideClick: false,
          confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
          // cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({
              name: "voluntary-exam",
              params: {
                cId: route.params.id,
                secId: secId,
                chId: chId,
                exId: exId,
                type: type,
              },
            });
          }
        });
      };
      // const postExam = (chId, exId) => {
      //   Swal.fire({
      //     title:
      //       "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
      //     text:
      //       "เกณฑ์การตัดสิน : คุณต้องได้คะแนนสอบมากกว่า 60% และทำข้อสอบได้ไม่เกิน 3 ครั้ง หากไม่ผ่าน ความคืบหน้าในบทเรียนนั้นจะถูกรีเซ็ตเป็น 0",
      //     icon: "warning",
      //     showCancelButton: false,
      //     confirmButtonColor: "#323563",
      //     cancelButtonColor: "#d33",
      //     allowOutsideClick: false,
      //     confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
      //     // cancelButtonText: "ยกเลิก",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       router.push({
      //         name: "voluntary-exam",
      //         params: {
      //           cId: route.params.id,
      //           chId: chId,
      //           exId: exId,
      //           type: "posttest",
      //         },
      //       });
      //     }
      //   });
      // };
      const detail = () => {
        showDetail.value = true;
        showDocument.value = false;
      };
      const document = () => {
        showDetail.value = false;
        showDocument.value = true;
      };
      const bookmark = () => {
        const formData = new FormData();
        formData.append("syllabusId", "-1");
        formData.append(
          "bookmark",
          showBookmark.value == true ? "false" : "true"
        );
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/api/v1/courses/" +
              route.params.id +
              "/chapter/" +
              currentChId.value +
              "/bookmark",
            formData,
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((res) => {
            getBookmark();
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const share = (social) => {
        const url = new URL(window.location.href);
        if (social == "facebook") {
          window.open(
            "http://facebook.com/sharer/sharer.php?u=" +
              encodeURIComponent(url.href),
            "",
            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          );
        } else if (social == "twitter") {
          window.open(
            "http://www.twitter.com/share?url=" + encodeURIComponent(url.href),
            "",
            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          );
        } else {
          window.open(
            "https://social-plugins.line.me/lineit/share?url=" +
              encodeURIComponent(url.href),
            "",
            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          );
        }
      };
      const openFile = (secId, chId, file, type) => {
        currentUpdate.value.sectionId = secId;
        currentUpdate.value.chapterId = chId;
        if (type == "youtube") {
          showVideo.value = false;
          showYoutube.value = true;
          // https://www.youtube.com/embed/
          let idYoutub = file.split("v=")[1];
          currentYoutube.value = "https://www.youtube.com/embed/" + idYoutub;
        } else {
          window.open(process.env.VUE_APP_IMG_URL + file, "_blank");
        }
      };
      const updateProgress = async () => {
        const formData = new FormData();
        formData.append("courseId", route.params.id + "");
        formData.append("sectionId", currentUpdate.value.sectionId);
        formData.append("chapterId", currentUpdate.value.chapterId);
        // console.log("update");
        await axios
          .post(process.env.VUE_APP_API_URL + "/updateProgress", formData, {
            headers: {
               token: localStorage.getItem("id_token")
            },
          })
          .then((res) => {
            // console.log(res);
            getDataList();
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      };
      return {
        getDataList,
        listData,
        listSection,
        getChapterList,
        getBookmark,
        getDocuments,
        videoRef,
        getExam,
        videoExam,
        currentChId,
        showDetail,
        showDocument,
        showBookmark,
        detail,
        document,
        bookmark,
        listDocument,
        share,
        currentVideo,
        openFile,
        showYoutube,
        currentYoutube,
        showVideo,
        updateProgress,
        currentUpdate,
        currentDuration,
        videoReload
      };
    },
    methods: {
      onPlay() {
      
      },
      async logDuration() {
        let flgSuccess = false;
        for (const loopSection of listSection.value) {
          if (currentUpdate.value.sectionId == loopSection.id) {
            for (const loopChapter of loopSection.chapters) {
              if (loopChapter.chapterId == currentUpdate.value.chapterId) {
                flgSuccess = loopChapter.flgSuccess;
              }
            }
          }
        }
        const data = {
          duration: videoRef.value.duration,
          elapsed: videoRef.value.currentTime,
          remaining: videoRef.value.duration - videoRef.value.currentTime,
          scoreAverage:
            (videoRef.value.currentTime / videoRef.value.duration) * 100,
        };
        const formData = new FormData();
        formData.append("courseId", this.$route.params.id + "");
        formData.append("sectionId", currentUpdate.value.sectionId);
        formData.append("chapterId", currentUpdate.value.chapterId);
        formData.append("duration", data.duration);
        formData.append("elapsed", data.elapsed);
        if (flgSuccess == false) {
          // console.log("update");
  
          await axios
            .post(
              process.env.VUE_APP_API_URL + "/updateVideoProgress",
              formData,
              {
                headers: {
                   token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              // console.log(res);
            })
            .catch((error) => {
              // console.log(error);
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      },
      formatDate(date) {
        const event = new Date(date);
        return event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
      formatDateTime(date) {
        const event = new Date(date);
        return (
          event.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }) + " น."
        );
      },
    },
  });
  </script>
  <style>
  .btn-default {
    background: #ffffff;
    border: 1px solid #323563 !important;
    color: #323563 !important;
    border-radius: 10px;
  }
  .btn-lern {
    background: #ffffff;
    border: 1px solid #323563 !important;
    color: #323563 !important;
    border-radius: 21px;
  }
  .btn-complete {
    background: #7f7f81;
    border: 1px solid #7f7f81;
    color: #ffffff;
    border-radius: 10px;
  }
  .btn-app {
    background: #da595a;
    color: #ffffff;
    border-radius: 100px;
  }
  .btn-app2 {
    background: #323563;
    color: #ffffff;
    border-radius: 100px;
  }
  .btn-register {
    background: #f2a17d;
    color: #ffffff;
    width: 70%;
    border-radius: 21px;
  }
  </style>
  