
  import { defineComponent, onMounted, ref } from "vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import axios from "axios";
  import { useRoute, useRouter } from "vue-router";
  const videoReload = ref(0);
  const currentVideo = ref();
  const currentYoutube = ref();
  const listData = ref();
  const listSection = ref();
  const videoRef = ref();
  const currentChId = ref();
  const showDetail = ref(true);
  const showDocument = ref(false);
  const showBookmark = ref(false);
  const listDocument = ref();
  const showYoutube = ref(false);
  const showVideo = ref(false);
  const currentUpdate = ref({
    sectionId: "",
    chapterId: "",
  });
  const currentDuration = ref(0);
  import Swal from "sweetalert2/dist/sweetalert2.min.js";

  export default defineComponent({
    name: "exam-learn",
    components: {},
    data() {
      return {
        imageUrl: process.env.VUE_APP_IMG_URL,
      };
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      onMounted(() => {
        /* eslint-disable */
        getDataList();
        // getDocuments();
        // getBookmark();
      });
      const getDataList = () => {
        showVideo.value = false;
        axios
          .get(
            process.env.VUE_APP_API_URL + "/getCourse?id=" + route.params.id,
            // "&categories=" +
            // categories.value
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.data.content[0]);
            listData.value = response.data.data.content[0];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getChapterList();
            setCurrentPageBreadcrumbs(listData.value.title, []);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const getChapterList = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getCourseSection?courseId=" +
              route.params.id,
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.data.content);
            listSection.value = response.data.data.content;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            // videoExam(listData.value.chapter.chapterId, false);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const getDocuments = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/api/v1/courses/" +
              route.params.id +
              "/documents",
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            listDocument.value = response.data.result[0].downloadFiles;
            // console.log(response.data.result[0].downloadFiles);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const getBookmark = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/api/v1/courses/" +
              route.params.id +
              "/bookmark",
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.result);
            if (response.data.result.length > 0) {
              showBookmark.value = true;
            } else {
              showBookmark.value = false;
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const videoExam = async (secId, chId, autoPlay) => {
        currentUpdate.value.sectionId = secId;
        currentUpdate.value.chapterId = chId;
        showVideo.value = true;
        showYoutube.value = false;
        currentChId.value = chId;
        let flgSuccess = false;
        for (const loopSection of listSection.value) {
          if (secId == loopSection.id) {
            for (const loopChapter of loopSection.chapters) {
              if (loopChapter.chapterId == chId) {
                // console.log(loopChapter);
                currentVideo.value =
                  process.env.VUE_APP_IMG_URL + loopChapter.videoPath;
                flgSuccess = loopChapter.flgSuccess;
                videoReload.value += 1;
              }
            }
          }
        }
        videoRef.value.load();
        if (flgSuccess != true) {
          let currentTime = await axios.get(
            process.env.VUE_APP_API_URL +
              `/getCourseVideoProgress?courseId=${route.params.id}&sectionId=${secId}&chapterId=${chId}`,
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          );
          if (currentTime.data.data.length > 0) {
            videoRef.value.currentTime = currentTime.data.data[0].elapsed;
          }
        }
        if (autoPlay == true) {
          videoRef.value.play();
        }
      };
      const getExam = (secId, chId, exId, type) => {
        // console.log(chId);
        Swal.fire({
          title: "แบบทดสอบก่อนเรียน",
          text:
            "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          allowOutsideClick: false,
          confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
          // cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({
              name: "voluntary-exam",
              params: {
                cId: route.params.id,
                secId: secId,
                chId: chId,
                exId: exId,
                type: type,
              },
            });
          }
        });
      };
      // const postExam = (chId, exId) => {
      //   Swal.fire({
      //     title:
      //       "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
      //     text:
      //       "เกณฑ์การตัดสิน : คุณต้องได้คะแนนสอบมากกว่า 60% และทำข้อสอบได้ไม่เกิน 3 ครั้ง หากไม่ผ่าน ความคืบหน้าในบทเรียนนั้นจะถูกรีเซ็ตเป็น 0",
      //     icon: "warning",
      //     showCancelButton: false,
      //     confirmButtonColor: "#323563",
      //     cancelButtonColor: "#d33",
      //     allowOutsideClick: false,
      //     confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
      //     // cancelButtonText: "ยกเลิก",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       router.push({
      //         name: "voluntary-exam",
      //         params: {
      //           cId: route.params.id,
      //           chId: chId,
      //           exId: exId,
      //           type: "posttest",
      //         },
      //       });
      //     }
      //   });
      // };
      const detail = () => {
        showDetail.value = true;
        showDocument.value = false;
      };
      const document = () => {
        showDetail.value = false;
        showDocument.value = true;
      };
      const bookmark = () => {
        const formData = new FormData();
        formData.append("syllabusId", "-1");
        formData.append(
          "bookmark",
          showBookmark.value == true ? "false" : "true"
        );
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/api/v1/courses/" +
              route.params.id +
              "/chapter/" +
              currentChId.value +
              "/bookmark",
            formData,
            {
              headers: {
                 token: localStorage.getItem("id_token")
              },
            }
          )
          .then((res) => {
            getBookmark();
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const share = (social) => {
        const url = new URL(window.location.href);
        if (social == "facebook") {
          window.open(
            "http://facebook.com/sharer/sharer.php?u=" +
              encodeURIComponent(url.href),
            "",
            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          );
        } else if (social == "twitter") {
          window.open(
            "http://www.twitter.com/share?url=" + encodeURIComponent(url.href),
            "",
            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          );
        } else {
          window.open(
            "https://social-plugins.line.me/lineit/share?url=" +
              encodeURIComponent(url.href),
            "",
            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          );
        }
      };
      const openFile = (secId, chId, file, type) => {
        currentUpdate.value.sectionId = secId;
        currentUpdate.value.chapterId = chId;
        if (type == "youtube") {
          showVideo.value = false;
          showYoutube.value = true;
          // https://www.youtube.com/embed/
          let idYoutub = file.split("v=")[1];
          currentYoutube.value = "https://www.youtube.com/embed/" + idYoutub;
        } else {
          window.open(process.env.VUE_APP_IMG_URL + file, "_blank");
        }
      };
      const updateProgress = async () => {
        const formData = new FormData();
        formData.append("courseId", route.params.id + "");
        formData.append("sectionId", currentUpdate.value.sectionId);
        formData.append("chapterId", currentUpdate.value.chapterId);
        // console.log("update");
        await axios
          .post(process.env.VUE_APP_API_URL + "/updateProgress", formData, {
            headers: {
               token: localStorage.getItem("id_token")
            },
          })
          .then((res) => {
            // console.log(res);
            getDataList();
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      };
      return {
        getDataList,
        listData,
        listSection,
        getChapterList,
        getBookmark,
        getDocuments,
        videoRef,
        getExam,
        videoExam,
        currentChId,
        showDetail,
        showDocument,
        showBookmark,
        detail,
        document,
        bookmark,
        listDocument,
        share,
        currentVideo,
        openFile,
        showYoutube,
        currentYoutube,
        showVideo,
        updateProgress,
        currentUpdate,
        currentDuration,
        videoReload
      };
    },
    methods: {
      onPlay() {
      
      },
      async logDuration() {
        let flgSuccess = false;
        for (const loopSection of listSection.value) {
          if (currentUpdate.value.sectionId == loopSection.id) {
            for (const loopChapter of loopSection.chapters) {
              if (loopChapter.chapterId == currentUpdate.value.chapterId) {
                flgSuccess = loopChapter.flgSuccess;
              }
            }
          }
        }
        const data = {
          duration: videoRef.value.duration,
          elapsed: videoRef.value.currentTime,
          remaining: videoRef.value.duration - videoRef.value.currentTime,
          scoreAverage:
            (videoRef.value.currentTime / videoRef.value.duration) * 100,
        };
        const formData = new FormData();
        formData.append("courseId", this.$route.params.id + "");
        formData.append("sectionId", currentUpdate.value.sectionId);
        formData.append("chapterId", currentUpdate.value.chapterId);
        formData.append("duration", data.duration);
        formData.append("elapsed", data.elapsed);
        if (flgSuccess == false) {
          // console.log("update");
  
          await axios
            .post(
              process.env.VUE_APP_API_URL + "/updateVideoProgress",
              formData,
              {
                headers: {
                   token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              // console.log(res);
            })
            .catch((error) => {
              // console.log(error);
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      },
      formatDate(date) {
        const event = new Date(date);
        return event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
      formatDateTime(date) {
        const event = new Date(date);
        return (
          event.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }) + " น."
        );
      },
    },
  });
  